import {Bag, JobInputIndex} from "../services/classes/Bag";

export type JobRouteMatch = { id: string };
export type DataFileRouteMatch = { dataFileId: string, };
export type TaxonomyRouteMatch = { id: string, taxonomyId: string };
export type CmpDataBagRouteMatch = { id: string, id2: string };
export type ApprovalRouteMatch = { approvalId: string };
export type MithraHistoryState = undefined | {
    bag: Bag,
    bag2?: Bag,
    selected_job_input: JobInputIndex, // Not used yet
};

export const routes = {
    home: '/',
    login: '/login',

    // Links without any bag id's
    merge_1_supplier_normalization: '/m1/supplier-normalization',
    merge_1_supplier_normalization_master: '/m1/master/supplier-normalization',
    merge_1_client_categorization_review: '/m1/categorization',
    merge_1_client_taxonomy_builder: '/m1/taxonomy-builder',
    merge_1_client_taxonomy_builder_id: '/m1/taxonomy-builder/:taxonomyId',
    merge_1_common_suppliers: '/m1/common-suppliers',
    merge_1_common_categories: '/m1/common-categories',
    merge_1_common_categories_review: '/m1/common-categories/review',

    job_koi_sc: '/job/:id/spend-concentration',
    job_koi_cfo: '/job/:id/cash-flow-optimization',
    job_koi_ss: '/job/:id/supplier-segmentation',
    job_koi_ppv: '/job/:id/price-variance',
    job_koi_kdf: '/job/:id/categories',
    job_koi_sh: '/job/:id/supplier-hierarchy',

    job_ai: '/job/:id/automatic-classification',

    job_dashboard: '/job/:id/dashboard',
    job_old_dashboard: '/job/:id/dashboard-old',
    job_koi_bu: '/job/:id/koi-3',
    job_manage: '/job/:id/manage',

    job_synergy_v1_0: '/synergy-v1',
    job_synergy_v1_1: '/synergy-v1/:id/data',
    job_synergy_v1_2: '/synergy-v1/:id/:id2',
    job_synergy_v1_opportunities: '/synergy-v1/:id/:id2/opportunities',
    job_synergy_v1_dashboard: '/synergy-v1/:id/:id2/dashboard',
    job_synergy_v1_suppliers: '/synergy-v1/:id/:id2/suppliers',
    job_synergy_v1_categories: '/synergy-v1/:id/:id2/categories',

    synergy_v2: '/synergy-v2',
    synergy_v2_data: '/synergy-v2/:id/data',
    synergy_v2_dashboard: '/synergy-v2/:id/dashboard',
    synergy_v2_taxonomy_builder_id: '/synergy-v2/:id/taxonomy-builder/:taxonomyId',
    synergy_v2_taxonomy_viewer: '/synergy-v2/:id/taxonomy-viewer',
    synergy_v2_taxonomy_viewer_id: '/synergy-v2/:id/taxonomy-viewer/:taxonomyId',
    synergy_v2_review: '/synergy-v2/:id/categorization',
    synergy_v2_review_result: '/synergy-v2/:id/categorization/review',
    synergy_v2_opportunities: '/synergy-v2/:id/opportunity',

    job_opportunities: '/job/:id/opportunity',

    job_ai_cat_welcome: '/job/:id/categorization',
    job_ai_cat_busy: '/job/:id/categorization/wait',
    job_ai_cat_review: '/job/:id/categorization/review',
    job_ai_cat_result: '/job/:id/categorization/result',

    job_taxonomy_builder: '/job/:id/taxonomy-builder',
    job_taxonomy_builder_id: '/job/:id/taxonomy-builder/:taxonomyId',

    job_report: '/job/:id/report',

    approval: '/approval',
    approval_cat_detail: '/approval/category/:approvalId',
    approval_tax_detail: '/approval/taxonomy/:approvalId',

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Cleanup dashboard urls
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    cleanup_dashboard: '/c/:id/dashboard',
    supplier_normalization: '/c/:id/supplier-normalization',
    // categorization_welcome: '/c/:id/categorization',
    // categorization_busy: '/c/:id/categorization/wait',
    categorization_review: '/c/:id/categorization',
    // categorization_result: '/c/:id/categorization/result',
    taxonomy_builder: '/c/:id/taxonomy-builder',
    taxonomy_builder_id: '/c/:id/taxonomy-builder/:taxonomyId',
    koi_spend_concentration: '/c/:id/spend-concentration',
    data_manage: '/c/:id/data',
    data_home: '/c',

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Merge urls
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    merge_x_dashboard: '/m/:id/dashboard',
    merge_x_cleanup_dashboard: '/m/:id/cleanup/dashboard',
    merge_x_cleanup_taxonomy_viewer: '/m/:id/cleanup/taxonomy',
    merge_x_cleanup_taxonomy_viewer_id: '/m/:id/cleanup/taxonomy/:taxonomyId',
    merge_x_cleanup_supplier_normalization: '/m/:id/cleanup/supplier-normalization',
    merge_x_cleanup_categorization: '/m/:id/cleanup/categorization',

    merge_x_analytics_dashboard: '/m/:id/analytics/dashboard',
    merge_x_analytics_supplier_segmentation: '/m/:id/analytics/supplier-segmentation',
    merge_x_analytics_ppv: '/m/:id/analytics/purchase-price-variance',
    merge_x_analytics_spend_concentration: '/m/:id/analytics/spend-concentration',
    merge_x_analytics_explore_categories: '/m/:id/analytics/explore-categories',

    merge_x_merge_dashboard: '/m/:id/merge/dashboard',
    merge_x_merge_taxonomy_mapper_baseline: '/m/:id/merge/taxonomy/mapper/baseline',
    merge_x_merge_taxonomy_mapper_target: '/m/:id/merge/taxonomy/mapper',
    merge_x_merge_categorization: '/m/:id/merge/categorization',

    merge_x_master_dashboard: '/m/:id/master/dashboard',
    merge_x_master_taxonomy_viewer_baseline: '/m/:id/master/taxonomy/baseline',
    merge_x_master_taxonomy_builder_target: '/m/:id/master/taxonomy',
    merge_x_master_taxonomy_compare_un: '/m/:id/master/taxonomy/compare/un',
    merge_x_master_taxonomy_compare_baseline: '/m/:id/master/taxonomy/compare',

    //////////////////////////
    // Single dataset views //
    //////////////////////////
    single_data_home2: '/new',
    single_data_home: '/cleanup',
    single_data_manage: '/cleanup/:id/data',

    single_cleansing_dashboard: '/cleanup/:id/dashboard',
    single_cleansing_sup_nor: '/cleanup/:id/supplier-normalization',
    single_cleansing_cat_welcome: '/cleanup/:id/categorization',
    single_cleansing_cat_busy: '/cleanup/:id/categorization/wait',
    single_cleansing_cat_review: '/cleanup/:id/categorization/review',
    single_cleansing_cat_result: '/cleanup/:id/categorization/result',
    single_cleansing_taxonomy_builder: '/cleanup/:id/taxonomy-builder',
    single_cleansing_taxonomy_builder_id: '/cleanup/:id/taxonomy-builder/:taxonomyId',

    single_analytics_dashboard: '/analytics/:id/dashboard',
    single_analytics_koi_ss: '/analytics/:id/supplier-segmentation',
    single_analytics_koi_kdf: '/analytics/:id/categories',
    single_analytics_koi_ppv: '/analytics/:id/price-variance',
    single_analytics_koi_sc: '/analytics/:id/spend-concentration',
    // single_analytics_koi_cfo:   '/analytics/:id/cash-flow-optimization',
    // single_analytics_koi_sh:    '/analytics/:id/supplier-hierarchy',

    single_data_opportunities: '/analytics/opportunity',
    single_data_opportunities_id: '/analytics/:id/opportunity',

    single_data_report: '/analytics/:id/report',

    ///////////////////////////
    // Synergy dataset views //
    ///////////////////////////
    synergy_v3: '/synergy',
    synergy_v3_data: '/synergy/:id/data',
    synergy_v3_dashboard: '/synergy/:id/dashboard',
    synergy_v3_suppliers: '/synergy/:id/suppliers',
    synergy_v3_categories: '/synergy/:id/categories',
    synergy_v3_spend: '/synergy/:id/spend',

    synergy_merge_dashboard: '/synergy-merge/:id/dashboard',
    synergy_merge_taxonomy_viewer: '/synergy-merge/:id/taxonomy-viewer',
    synergy_merge_taxonomy_viewer_id: '/synergy-merge/:id/taxonomy-viewer/:taxonomyId',
    synergy_merge_taxonomy_builder: '/synergy-merge/:id/taxonomy-builder', // Not used?
    synergy_merge_taxonomy_builder_id: '/synergy-merge/:id/taxonomy-builder/:taxonomyId',
    synergy_merge_review: '/synergy-merge/:id/categorization',
    synergy_merge_review_result: '/synergy-merge/:id/categorization/review',

    synergy_v3_opportunities: '/synergy/opportunity',
    synergy_v3_opportunities_id: '/synergy/:id/opportunity',

    synergy_v3_report: '/synergy/:id/report',

    // Functionality oriented routes
    taxonomy_mapper: '/taxonomy-mapper/:id',
    taxonomy_mapper_review: '/taxonomy-mapper/:id/review',

    looker_preview: '/advanced-dashboard',
    looker_preview_id: '/job/:id/advanced-dashboard',

    // data-ingestion
    data_ingestion: '/data-ingestion',
    data_upload_mapping: '/data-upload/:dataFileId',
    data_upload: '/data-upload',
}
