import './TaxonomyMapper.scss';
import React from "react";
import {observer} from "mobx-react-lite";
import {useRouteMatch} from "react-router-dom";
import {JobRouteMatch, routes} from "../../routing/routes";
import {useStores} from "../../stores";
import {Button, Grid} from "@mui/material";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {generatePath, useHistory} from "react-router";
import Typography from "@mui/material/Typography";
import {BaseTaxonomySupplierTable} from "./mapper/BaseTaxonomySupplierTable";
import {TaxonomyMapperComponent} from "./mapper/TaxonomyMapperComponent";
import {ChevronRight, Refresh} from "@mui/icons-material";
import {TaxonomyMapperToolbar} from "./mapper/TaxonomyMapperToolbar";
import {generateHomePath} from "../../routing/routing.utils";
import {environment} from "../../env";

export const TaxonomyMapperPage: React.FC = observer(() => {
    const {rootAppStore, taxonomyMapperStore} = useStores();
    const routeMatch = useRouteMatch<JobRouteMatch>();
    const history = useHistory();
    // const theme = useTheme();
    // const mediaTablet = useMediaQuery(theme.breakpoints.down('md'));

    return <KoiFacade
        title="Taxonomy Mapper"
        // helpContent={<PurchasePriceVarianceHelp/>}
        intro={<></>}
        back={generateHomePath(routeMatch, undefined, rootAppStore.app)}
        next={{
            type: 'component',
            node: <Button
                color="primary"
                variant="outlined"
                endIcon={<ChevronRight/>}
                onClick={() => {

                    if (environment.package === 'merge_1') {
                        // In the merge_1 package there are no id's in the url
                        history.push(generatePath(routes.merge_1_common_categories_review, routeMatch.params))
                        return
                    }

                    // After the taxonomy mapper is completed, go to the review page
                    history.push(generatePath(routes.taxonomy_mapper_review, routeMatch.params))
                    // // TODO: CAT-712
                    // if (env === 'simple') {
                    //     // TaxonomyMapperReviewPage
                    //     history.push(generatePath(routes.pic_common_categories_review, routeMatch.params))
                    // } else if (env === 'nestle') {
                    //     history.push(generatePath(routes.nestle_common_categories_review, routeMatch.params))
                    // } else if (env === 'multi') {
                    //     // TaxonomyMapperReviewPage
                    //     history.push(generatePath(routes.synergy_merge_taxonomy_mapper_review, routeMatch.params))
                    // } else {
                    //     // TaxonomyMapperReviewPage
                    //     history.push(generatePath(routes.synergy_v2_taxonomy_review, routeMatch.params))
                    // }
                }}>
                Review
            </Button>
        }}
        className="taxonomy-mapper-page centered-header">

        <Grid container justifyContent="end" className="taxonomy-titles">
            <Grid item sm={4}>{/* Or 7 to let the title span over the table as well*/}
                <Typography variant="h3" className="taxonomy-title base-title">
                    {taxonomyMapperStore.baseTaxonomyName}
                </Typography>
            </Grid>
            <Grid item sm={1} className="text-center">
                <Button
                    aria-label="refresh"
                    onClick={() => window.location.reload()}
                    disabled={taxonomyMapperStore.mapper.busy}>
                    <Refresh/>
                </Button>
            </Grid>
            <Grid item sm={4}>
                <Typography variant="h3" className="taxonomy-title target-title">
                    {taxonomyMapperStore.targetTaxonomyName}
                </Typography>
            </Grid>
        </Grid>

        <section className="taxonomy-mapper-main">
            <Grid container className="taxonomy-mapper-main-header">
                <Grid item sm={3}/>
                <Grid item sm={9}>
                    <TaxonomyMapperToolbar/>
                </Grid>
            </Grid>
            <Grid container className="taxonomy-mapper-main-body">
                <Grid item sm={3}>
                    <BaseTaxonomySupplierTable/>
                </Grid>
                <Grid item sm={9} id="taxonomy-mapper-viz-wrapper">
                    {/*<Grid container>*/}
                    {/*    <Grid item xs={5}>a</Grid>*/}
                    {/*    <Grid item xs={2}>b</Grid>*/}
                    {/*    <Grid item xs={5}>c</Grid>*/}
                    {/*</Grid>*/}
                    <TaxonomyMapperComponent/>
                </Grid>
            </Grid>
        </section>
    </KoiFacade>
});
