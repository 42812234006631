import React, {useEffect} from "react";
import {Menu, MenuItem} from "@mui/material";
import {generatePath, useHistory} from "react-router";
import {environment} from "../../env";
import {DebugValue} from "./DebugValue";
import {useStores} from "../../stores";
import {observer} from "mobx-react-lite";
import Button from "@mui/material/Button";
import {routes} from "../../routing/routes";

export const DebugMenuBar: React.FC = observer(() => {
    const {debugStore, bagStore} = useStores();
    const history = useHistory();
    // const routeMatch = useRouteMatch();

    // const serverConfig = debugStore.config;
    // const celeryOk = debugStore.ping;
    const allBags = debugStore.bags;

    const [allJobsMenuAnchor, setAllJobsMenuAnchor] = React.useState<null | HTMLElement>(null);
    const [urlsMenuA, setUrlsMenuA] = React.useState<null | HTMLElement>(null);

    useEffect(() => {
        debugStore.fetch();
    }, [debugStore]);

    // // debug upload type
    // const [dbUploadType, setDbUploadType] = React.useState('naive_ds');
    // const [dbUploadTypes, setDbUploadTypes] = React.useState<string[]>(['naive_ds']);

    const allJobsMenuOpen = Boolean(allJobsMenuAnchor);
    return <div className="debug-components">
        <DebugValue name="customer" val={environment.customerName}/>
        <DebugValue name="env" val={environment.environmentName}/>
        <DebugValue name="prod" true_txt="🅿️" false_txt="🧑‍💻" val={environment.production}/>

        {/*<DebugValue name="AI" true_txt="on" false_txt="off" val={serverConfig ? !serverConfig.SKIP_AI_INTEGRATION : undefined}/>*/}
        <DebugValue name="bag" val={bagStore.bag?.name}/>

        <Button onClick={() => {
            throw new Error(`Throw new error message at ${new Date()} for testing...`)
        }}>
            Throw error
        </Button>

        {/*<DebugValue name="server.default_config" val={serverConfig ? serverConfig.default_type : undefined}/>*/}

        {/*<FormControl variant="standard">*/}
        {/*    <InputLabel id="debug-upload-config_label">Config</InputLabel>*/}
        {/*    <Select*/}
        {/*        variant="standard"*/}
        {/*        labelId="debug-upload-config_label"*/}
        {/*        id="debug-upload-config"*/}
        {/*        value={dbUploadType}*/}
        {/*        onChange={e => setDbUploadType(e.target.value as string)}*/}
        {/*    >*/}
        {/*        {dbUploadTypes.map(t => <MenuItem value={t} key={t}>*/}
        {/*        {t}*/}
        {/*    </MenuItem>)}*/}
        {/*    </Select>*/}
        {/*</FormControl>*/}

        <a target="_blank" rel="noopener noreferrer" href={environment.adminUrl}>
            <button>Admin</button>
        </a>
        <button onClick={e => setAllJobsMenuAnchor(e.currentTarget)}>Jobs</button>

        {/*<Menu id="menu-appbar"*/}
        {/*      anchorEl={allJobsMenuAnchor}*/}
        {/*      anchorOrigin={{vertical: 'top', horizontal: 'right',}}*/}
        {/*      keepMounted*/}
        {/*      transformOrigin={{vertical: 'top', horizontal: 'right',}}*/}
        {/*      open={allJobsMenuOpen}*/}
        {/*      onClose={() => setAllJobsMenuAnchor(null)}>*/}
        {/*    {allBags.map(bag =>*/}
        {/*        <MenuItem key={bag.id} onClick={() => {*/}
        {/*            history.push(generateHomePath(routeMatch, {id: bagId}, rootAppStore.app));*/}
        {/*            setAllJobsMenuAnchor(null);*/}
        {/*            window.location.reload();*/}
        {/*        }}>*/}
        {/*            {bag.id}: {bag.name}*/}
        {/*        </MenuItem>*/}
        {/*    )}*/}
        {/*</Menu>*/}

        <Button onClick={e => setUrlsMenuA(e.currentTarget)} className="value-other debug-value">
            routes
        </Button>
        <Menu id="menu-urls-dropdown"
              anchorEl={urlsMenuA}
              anchorOrigin={{vertical: 'top', horizontal: 'right',}}
              keepMounted
              transformOrigin={{vertical: 'top', horizontal: 'right',}}
              open={Boolean(urlsMenuA)}
              onClose={() => setUrlsMenuA(null)}>
            {Object.entries(routes).map(([name, urlSpec], i) =>
                <MenuItem
                    key={i}
                    onClick={() => {
                        const urlParams = {}
                        if (urlSpec.includes(':id/')) {
                            urlParams['id'] = 1;
                        }
                        if (urlSpec.includes(':id2/')) {
                            urlParams['id2'] = 2;
                        }
                        history.push(generatePath(urlSpec, urlParams))
                        setUrlsMenuA(null);
                    }}>
                    {name} ({urlSpec})
                </MenuItem>
            )}
        </Menu>


    </div>;
});
