import {generatePath, match} from "react-router";
import {routes} from "./routes";
import {environment} from "../env";
import {AppState} from "../stores/RootAppStore";

/**
 * TODO CAT-712
 * @deprecated Deprecate this function in favor of DashboardEnvironment
 */
export type AppEnvironment = 'synergy_v1'
    | 'taxonomy_merge'
    | 'default'
    | 'single_cleansing' | 'single_analytics' | 'synergy_v3' | 'synergy_merge'
    | 'pic'

/**
 * TODO CAT-712
 * @deprecated Deprecate this function in favor of DashboardEnvironment
 */
export function getAppEnvironment(pageUrl: string): AppEnvironment {
    switch (pageUrl) {
        case routes.single_data_home2:
        case routes.single_data_home:
        case routes.single_data_manage:
        case routes.single_cleansing_dashboard:
        case routes.single_cleansing_sup_nor:
        case routes.single_cleansing_cat_welcome:
        case routes.single_cleansing_cat_busy:
        case routes.single_cleansing_cat_review:
        case routes.single_cleansing_cat_result:
        case routes.single_cleansing_taxonomy_builder:
        case routes.single_cleansing_taxonomy_builder_id:
            return 'single_cleansing';
        case routes.single_analytics_dashboard:
        case routes.single_analytics_koi_ss:
        case routes.single_analytics_koi_kdf:
        case routes.single_analytics_koi_ppv:
        case routes.single_analytics_koi_sc:
        case routes.single_data_opportunities:    // dubious
        case routes.single_data_opportunities_id: // dubious
        case routes.single_data_report:           // dubious
            return 'single_analytics';
        case routes.synergy_v3:
        case routes.synergy_v3_data:
        case routes.synergy_v3_dashboard:
        case routes.synergy_v3_suppliers:
        case routes.synergy_v3_categories:
        case routes.synergy_v3_spend:
        case routes.synergy_v3_opportunities:    // dubious
        case routes.synergy_v3_opportunities_id: // dubious
        case routes.synergy_v3_report:           // dubious
            return 'synergy_v3';
        case routes.synergy_merge_dashboard:
        case routes.synergy_merge_taxonomy_viewer:
        case routes.synergy_merge_taxonomy_viewer_id:
        case routes.synergy_merge_taxonomy_builder:
        case routes.synergy_merge_taxonomy_builder_id:
        case routes.synergy_merge_review:
        case routes.synergy_merge_review_result:
            return 'synergy_merge';
        case routes.job_synergy_v1_0:
        case routes.job_synergy_v1_1:
        case routes.job_synergy_v1_2:
        case routes.job_synergy_v1_dashboard:
        case routes.job_synergy_v1_suppliers:
        case routes.job_synergy_v1_categories:
        case routes.job_synergy_v1_opportunities:
            return 'synergy_v1';
        case routes.synergy_v2:
        case routes.synergy_v2_data:
        case routes.synergy_v2_dashboard:
        case routes.synergy_v2_taxonomy_builder_id:
        case routes.synergy_v2_taxonomy_viewer:
        case routes.synergy_v2_taxonomy_viewer_id:
        case routes.synergy_v2_review:
        case routes.synergy_v2_review_result:
        case routes.synergy_v2_opportunities:
            return 'taxonomy_merge';
        default:
            return 'default';
    }
}

export function lookupHomePath(pageUrl: string, state?: AppState): string {
    if (environment.package === 'merge_1') {
        return routes.home;
    }
    if (environment.package === 'cleanup') {
        return routes.cleanup_dashboard;
    }
    if (environment.package === 'merge_x') {
        if (!state) return routes.merge_x_dashboard;
        if (state.dashboardEnvironment === 'cleanup') return routes.merge_x_cleanup_dashboard;
        if (state.dashboardEnvironment === 'analytics') return routes.merge_x_analytics_dashboard;
        if (state.dashboardEnvironment === 'merge') return routes.merge_x_merge_dashboard;
        return routes.merge_x_dashboard;
    }

    // TODO CAT-712
    switch (pageUrl) {
        // The routes that cannot go to the dashboard have different behavior
        case routes.job_synergy_v1_0:
            return routes.job_synergy_v1_0
        case routes.job_synergy_v1_1:
            return routes.job_synergy_v1_1
        case routes.synergy_v2:
            return routes.synergy_v2
        case routes.approval:
            // Environment where there is no bag present
            return '/'
        case routes.approval_cat_detail:
        case routes.approval_tax_detail:
            // Special case
            return routes.approval;
        default:
            // The rest goes to the dashboard
            switch (getAppEnvironment(pageUrl)) {
                case "single_cleansing":
                    return routes.single_cleansing_dashboard;
                case "single_analytics":
                    return routes.single_analytics_dashboard;
                case "synergy_v3":
                    return routes.synergy_v3_dashboard;
                case "synergy_merge":
                    return routes.synergy_merge_dashboard;
                case "synergy_v1":
                    return routes.job_synergy_v1_dashboard;
                case "taxonomy_merge":
                    return routes.synergy_v2_dashboard;
                case "default":
                    return routes.job_dashboard;
            }
            return '/';
    }
}

export function generateHomePath(routeMatch: match<any>, params?: any, state?: AppState) {
    return generatePath(lookupHomePath(routeMatch.path, state), params ? params : routeMatch.params);
}

export function generateGotoDashboardPath(routeMatch: match<any>, params?: any, state?: AppState) {
    if (environment.package === 'merge_1') {
        return generateHomePath(routeMatch, params, state);
    }
    if (environment.package === 'cleanup') {
        return generateHomePath(routeMatch, params, state);
    }
    if (environment.package === 'merge_x') {
        return generateHomePath(routeMatch, params, state);
    }

    // TODO CAT-712
    // The rest goes to the dashboard
    let url;
    switch (getAppEnvironment(routeMatch.path)) {
        case "synergy_v1":
            url = routes.job_synergy_v1_dashboard;
            break;
        case "taxonomy_merge":
            url = routes.synergy_v2_dashboard;
            break;
        case "default":
            url = routes.job_dashboard;
            break;
        case "single_cleansing":
        case "single_analytics":
        case "synergy_v3":
        case "synergy_merge":
            return generateHomePath(routeMatch, params);
        default:
            url = '/';
            break;
    }
    console.log('generateGotoDashboardPath', url);
    return generatePath(url, params ? params : routeMatch.params);
}
