import {makeAutoObservable, reaction, toJS} from "mobx";
import {CategoryCompareManager} from "./CategoryCompareManager";
import {Data} from "../../components/visualization/cmp-breakdown-single/CmpBreakdownSingleVisualization";
import {hardcoded_dpw} from "./hardcoded/hardcoded_dpw";
import {toCurrency} from "../../components/currency-component/CurrencyComponent";
import ProfileStore from "../../stores/ProfileStore";
import {AxoisRequestManager, RequestManager} from "../../stores/managers/RequestManager";
import {AxiosResponse} from "axios";
import {from} from "rxjs";
import {Bag} from "../../services/classes/Bag";
import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {MatCommonKpiSerializer} from "../../services/ApiTypes";

export class CompareBagStore {

    desiredMasterBagId?: number
    masterBag?: any
    masterBagNotFound = false;

    compareBag?: any
    compareBagNotFound = false;

    readonly cmpCat = new CategoryCompareManager(this.api, this.profileStore)
    readonly _bagRequestManager = new RequestManager<{ databag: number }, AxiosResponse<Bag>>(
        ({databag}) => from(this.api.getBag(databag))
    );

    constructor(private api: MithraMaterializedApi, private profileStore: ProfileStore) {
        makeAutoObservable(this)
        reaction(() => ([this._bagRequestManager.result?.data, this._bagRequestManager.error]), ([bag, err]) => {
            console.log('CompareBagStore: Setting master bag', toJS(bag), err);
            if (err) console.warn('CompareBagStore: Error while loading master bag: ' + err)
            if (bag) {
                this.masterBag = bag;
                this.masterBagNotFound = false;
            } else {
                this.masterBag = undefined;
                this.masterBagNotFound = true;
            }
        })
    }

    setMasterBag(bag: any) {
        this.masterBag = bag;
    }

    getMasterBag() {
        return this.masterBag || DEFAULT_BASE_BAG
    }

    getMasterBagName() {
        return this.getMasterBag().name
    }

    setCompareBag(bag: any) {
        this.compareBag = bag;
    }

    getCmpBag() {
        return this.compareBag || DEFAULT_CMP_BAG
    }

    getCmpBagName() {
        return this.getCmpBag().name;
    }

    initBags(desiredMasterBagId: number) {
        console.log('CompareBagStore: Init compare bags', desiredMasterBagId)
        this.desiredMasterBagId = desiredMasterBagId;
        if (this.masterBag || this.masterBagNotFound) {
            console.log('CompareBagStore: Init compare bags, skipped')
            // Databag is already present
            return;
        }
        if (isNaN(desiredMasterBagId)) {
            this.masterBagNotFound = true;
        } else {
            this._bagRequestManager.request({databag: desiredMasterBagId})
            this._masterKpiManager.request(desiredMasterBagId)
        }
    }

    setMissingMasterBag() {
        this.desiredMasterBagId = undefined;
        this.masterBagNotFound = true;
        this._bagRequestManager.cleanup();
        this.masterBag = undefined;
    }

    get supplierCompareBreakdown() {
        const masterData: Data = {
            top: {
                total: hardcoded_dpw.MASTER.n_suppliers,
                value: hardcoded_dpw.OVERLAP.overlap_n_suppliers,
                valueTitle: Math.round(hardcoded_dpw.MASTER.n_suppliers_overlap_P * 100) + '%',
                valueTitleHover: '',
            },
            bottom: {
                total: hardcoded_dpw.MASTER.total_spend,
                value: hardcoded_dpw.MASTER.total_spend_overlap,
                valueTitle: Math.round(hardcoded_dpw.MASTER.overlap_supplier_spend_P * 100) + '%',
                valueTitleHover: toCurrency(this.profileStore.currencySymbol, hardcoded_dpw.MASTER.total_spend_overlap),
            }
            // toCurrencyWithP(c, cP)
        }
        const cmpData: Data = {
            top: {
                total: hardcoded_dpw.COMPARE.n_suppliers,
                value: hardcoded_dpw.OVERLAP.overlap_n_suppliers,
                valueTitle: Math.round(hardcoded_dpw.COMPARE.n_suppliers_overlap_P * 100) + '%',
                valueTitleHover: '',
            },
            bottom: {
                total: hardcoded_dpw.COMPARE.total_spend,
                value: hardcoded_dpw.COMPARE.total_spend_overlap,
                valueTitle: Math.round(hardcoded_dpw.COMPARE.overlap_supplier_spend_P * 100) + '%',
                valueTitleHover: toCurrency(this.profileStore.currencySymbol, hardcoded_dpw.COMPARE.total_spend_overlap),
            }
            // toCurrencyWithP(c, cP)
        }
        return {masterData, cmpData};
    }

    /**
     * Somewhat magical function to determine which is the master dataset, given some dataset
     * TODO: Very hacky, but it works for now
     * @param bag
     */
    determineMasterBag(bag: Bag | undefined | { id: number }): number | undefined | null {
        if (!bag) return undefined;
        const masterBagId = this.profileStore.p.hardcodeMasterBagId;
        if (!masterBagId) return undefined;
        if (bag.id === masterBagId) {
            console.warn('Warning: Master bag is the same as the selected bag...')
            // For demonstration purposes
            return masterBagId;
        }
        return masterBagId;
    }

    _masterKpiManager = new AxoisRequestManager<number, MatCommonKpiSerializer>(bagId => from(this.api.getCommonKpi(bagId)))

    get masterKpi() {
        return this._masterKpiManager.result;
    }
}

export const DEFAULT_BASE_BAG = {id: 1, name: 'Master spend data', date: '20-11-2021'};
export const DEFAULT_CMP_BAG = {id: 2, name: 'Compare data', date: '5-12-2021'};
