import {styled} from '@mui/material/styles';
import React, {useEffect} from "react";
import {useStores} from "../../../stores";
import {Bag} from "../../../services/classes/Bag";
import {useHistory} from "react-router";
import {forkJoin} from "rxjs";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {observer} from "mobx-react-lite";
import {SelectDatasetMenu} from "../../_tiny/SelectDatasetMenu";
import {useRouteMatch} from "react-router-dom";

const PREFIX = 'SelectDataset';

const classes = {
    formControl: `${PREFIX}-formControl`,
    selectEmpty: `${PREFIX}-selectEmpty`,
    formEditIcon: `${PREFIX}-formEditIcon`
};

const StyledFormControl = styled(FormControl)(({theme}) => ({
    [`&.${classes.formControl}`]: {
        margin: theme.spacing(1),
        minWidth: 300,
        minHeight: '4em',
    },

    [`& .${classes.selectEmpty}`]: {
        marginTop: theme.spacing(2),
    },

    [`& .${classes.formEditIcon}`]: {
        margin: theme.spacing(1),
    }
}));

const menuProps = {
    id: 'header-databag-menu',
};

export const SelectDataset: React.FC = observer(() => {

    const {bagStore} = useStores();
    const bag = bagStore.bag as Bag;
    const routeMatch = useRouteMatch();
    const history = useHistory();


    useEffect(() => {
        const sub = forkJoin([
            bagStore.getAllBags()
        ]).subscribe(([_bags]) => {
            bagStore.setAllBags(_bags)
        });
        return () => {
            // TODO: The select-dataset is reloaded when the page is changed, this can be optimized
            //  This component is unloaded when the router changes, pull the component higher up in the route chain
            sub.unsubscribe();
        };
    }, [bagStore])

    if (!bagStore.allBags)
        return <StyledFormControl className={classes.formControl}>
            <InputLabel id="selected-bag-loading-label">&nbsp;</InputLabel>
            <Select
                variant="standard"
                labelId="selected-bag-loading-label"
                id="selected-bag-loading"
                className="select-bag-field is-loading"
                value="Loading..."
                disabled>
                <MenuItem value="Loading..."/>
            </Select>
        </StyledFormControl>;

    if (!bag) return <></>;
    return <StyledFormControl className={classes.formControl} variant="standard">
        <SelectDatasetMenu
            variant="standard"
            labelId="selected-bag-label"
            id="selected-bag"
            value={bag.id}
            onChange={event => {
                const bagId = event.target.value;
                bagStore.changeBag(bagId, history, routeMatch);
            }}
            // displayEmpty
            className={classes.selectEmpty + ' select-bag-field'}
            // disabled={disabled}
            bags={bagStore.allBags}
            MenuProps={menuProps}
        />
    </StyledFormControl>
})
