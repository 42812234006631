import {DashboardSwitcher} from "./DashboardSwitcher";
import React from "react";
import {SelectDataset} from "../../../components/main/header/SelectDataset";
import {MultiDropdownMenu} from "../menu/MultiDropdownMenu";


export const MergeXHeader: React.FC = () => {
    return <>
        <div style={{flexGrow: 1}}/>
        <DashboardSwitcher/>
        <SelectDataset/>
        <MultiDropdownMenu/>
    </>;
}
