import {observer} from "mobx-react-lite";
import {
    Button,
    ButtonGroup,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Switch,
    Typography
} from "@mui/material";
import {Clear, Link, LinkOff, PendingOutlined, Search} from "@mui/icons-material";
import React from "react";
import {useStores} from "../../../stores";
import {environment} from "../../../env";
import {TaxonomyMapFilterDelegate} from "../store/TaxonomyMapFilterDelegate";

export const TaxonomyMapperToolbar: React.FC = observer(() => {
    const {taxonomyMapperStore} = useStores();
    return <Grid container justifyContent="center" className="taxonomy-mapper-toolbar">
        <Grid item xs={5} className="left-container"
              container justifyContent="center">
            <Grid item className="text-center">
                <div role="rowgroup">
                    <ButtonGroup className="level-selection-group">
                        {[...Array(TaxonomyMapFilterDelegate.LEFT_TAXONOMY_SIZE)].map((_, i) =>
                            <Button
                                key={i}
                                onClick={() => {
                                    if (i === taxonomyMapperStore.filter.leftLastSelectedLevel) {
                                        taxonomyMapperStore.filter.setLeftLastSelectedLevel(TaxonomyMapFilterDelegate.LEFT_TAXONOMY_SIZE - 1)
                                        taxonomyMapperStore.treeController.openTreeCompletely(true)
                                    } else {
                                        taxonomyMapperStore.filter.setLeftLastSelectedLevel(i)
                                        taxonomyMapperStore.filter.collapseLeft(i)
                                    }
                                }}
                                className={taxonomyMapperStore.filter.leftLastSelectedLevel === i ? 'last-selected' : ''}
                                variant="outlined">
                                <span className="text">
                                    {/*{`L${i + 1}`}*/}
                                    {i === TaxonomyMapFilterDelegate.LEFT_TAXONOMY_SIZE - 1 ? 'All' : `L${i + 1}`}
                                </span>
                            </Button>
                        )}
                    </ButtonGroup>
                    <Button
                        className="remaining-filter-button"
                        onClick={() => taxonomyMapperStore.filter.setHighlightUnmatched(!taxonomyMapperStore.filter.highlightUnmatched)}
                        variant="outlined"
                        title={taxonomyMapperStore.filter.highlightUnmatched
                            ? 'Clear highlight'
                            : 'Highlight unmatched categories'
                        }>
                        {taxonomyMapperStore.filter.highlightUnmatched
                            ? <Clear/>
                            : <PendingOutlined/>
                        }
                    </Button>
                    {/*<Button onClick={() => {*/}
                    {/*    // taxonomyMapperStore.treeController.controller.leftTree*/}
                    {/*    taxonomyMapperStore.treeController.controller.leftTree.nodeGroup.each(function (d) {*/}
                    {/*        if(d.label === 'UNPROCESSED') {*/}
                    {/*            const el = d3.select(this);*/}
                    {/*            const event = new MouseEvent('click', {*/}
                    {/*            });*/}
                    {/*            el.node()?.dispatchEvent(event)*/}
                    {/*        }*/}
                    {/*    })*/}
                    {/*}}>*/}
                    {/*    UNPROCESSED*/}
                    {/*</Button>*/}
                </div>
                <div role="rowgroup">
                    <FormControl variant="standard" fullWidth={true} className="search-field">
                        <InputLabel htmlFor="left-taxonomy-search-field">
                            Search
                            {taxonomyMapperStore.filter.leftHits !== undefined &&
                                ` (${taxonomyMapperStore.filter.leftHits} result${taxonomyMapperStore.filter.leftHits === 1 ? '' : 's'})`
                            }
                        </InputLabel>
                        <Input
                            id="left-taxonomy-search-field"
                            type="text"
                            fullWidth={true}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="clear search"
                                        onClick={() => taxonomyMapperStore.filter.clearSearch(true)}
                                    >
                                        <Clear/>
                                    </IconButton>
                                    <IconButton
                                        aria-label="search"
                                        onClick={() => taxonomyMapperStore.filter.doSearch(true)}
                                    >
                                        <Search/>
                                    </IconButton>
                                </InputAdornment>
                            }
                            value={taxonomyMapperStore.filter.leftSearchString}
                            onChange={e => taxonomyMapperStore.filter.setSearch(true, e.target.value)}
                            onKeyDown={e => {
                                if (e.key === "Enter") {
                                    taxonomyMapperStore.filter.doSearch(true)
                                }
                            }}
                        />
                    </FormControl>
                </div>
            </Grid>
        </Grid>

        <Grid item xs={2} className="text-center toolbar-center-section" alignSelf="stretch">
            <Button
                className="connect-button"
                variant="outlined"
                startIcon={taxonomyMapperStore.selection.isConnected ? <LinkOff/> : <Link/>}
                disabled={!taxonomyMapperStore.selection.canConnect}
                onClick={() => taxonomyMapperStore.selection.onClickToggleConnection()}>
                {taxonomyMapperStore.selection.isConnected ? 'Disconnect' : 'Connect'}
            </Button>
            {environment.isTest && <div>queue: {taxonomyMapperStore.mapper._createDeletePipe.m.nInPipe}</div>}
        </Grid>
        <Grid item xs={5} className="right-container"
              container justifyContent="space-evenly">
            <Grid item className="text-center">
                <ButtonGroup className="level-selection-group">
                    {[...Array(TaxonomyMapFilterDelegate.RIGHT_TAXONOMY_SIZE)].map((_, i) =>
                        <Button
                            key={i}
                            color="primary"
                            onClick={() => {
                                if (i === taxonomyMapperStore.filter.rightLastSelectedLevel) {
                                    taxonomyMapperStore.filter.setRightLastSelectedLevel(TaxonomyMapFilterDelegate.RIGHT_TAXONOMY_SIZE - 1)
                                    taxonomyMapperStore.treeController.openTreeCompletely(false);
                                } else {
                                    taxonomyMapperStore.filter.setRightLastSelectedLevel(i)
                                    taxonomyMapperStore.filter.collapseRight(i)
                                }
                            }}
                            className={taxonomyMapperStore.filter.rightLastSelectedLevel === i ? 'last-selected' : ''}
                            variant="outlined">
                            <span className="text">
                                {/*{`L${i + 1}`}*/}
                                {i === TaxonomyMapFilterDelegate.RIGHT_TAXONOMY_SIZE - 1 ? 'All' : `L${i + 1}`}
                            </span>
                        </Button>
                    )}
                </ButtonGroup>
                <div role="rowgroup">
                    <FormControl variant="standard" fullWidth={true} className="search-field">
                        <InputLabel htmlFor="right-taxonomy-search-field">
                            Search
                            {taxonomyMapperStore.filter.rightHits !== undefined &&
                                ` (${taxonomyMapperStore.filter.rightHits} result${taxonomyMapperStore.filter.rightHits === 1 ? '' : 's'})`
                            }
                        </InputLabel>
                        <Input
                            id="right-taxonomy-search-field"
                            type="text"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="clear search"
                                        onClick={() => taxonomyMapperStore.filter.clearSearch(false)}
                                    >
                                        <Clear/>
                                    </IconButton>
                                    <IconButton
                                        aria-label="search"
                                        onClick={() => taxonomyMapperStore.filter.doSearch(false)}
                                    >
                                        <Search/>
                                    </IconButton>
                                </InputAdornment>
                            }
                            value={taxonomyMapperStore.filter.rightSearchString}
                            onChange={e => taxonomyMapperStore.filter.setSearch(false, e.target.value)}
                            onKeyDown={e => {
                                if (e.key === "Enter") {
                                    taxonomyMapperStore.filter.doSearch(false)
                                }
                            }}
                        />
                    </FormControl>
                </div>
            </Grid>
            <Grid item>
                <FormGroup>
                    <FormControlLabel
                        className="automatic-folding-control"
                        control={<Switch
                            checked={taxonomyMapperStore.autoUpdateConnectedRight}
                            onChange={e => taxonomyMapperStore.setAutoUpdateConnectedLeft(e.target.checked)}
                        />}
                        label={<Typography className="label">
                            <span>Automatic</span>
                            <span>folding</span>
                        </Typography>}
                    />
                </FormGroup>
                {/*<ButtonGroup className="auto-manual-selection-group">*/}
                {/*    <Button*/}
                {/*        color="primary"*/}
                {/*        className={!taxonomyMapperStore.autoUpdateConnectedRight ? 'active' : 'inactive'}*/}
                {/*        variant={!taxonomyMapperStore.autoUpdateConnectedRight ? 'contained' : 'outlined'}*/}
                {/*        onClick={() => taxonomyMapperStore.setAutoUpdateConnectedLeft(false)}>*/}
                {/*        <ManualHandIcon/>*/}
                {/*    </Button>*/}
                {/*    <Button*/}
                {/*        color="primary"*/}
                {/*        className={taxonomyMapperStore.autoUpdateConnectedRight ? 'active' : 'inactive'}*/}
                {/*        variant={taxonomyMapperStore.autoUpdateConnectedRight ? 'contained' : 'outlined'}*/}
                {/*        onClick={() => taxonomyMapperStore.setAutoUpdateConnectedLeft(true)}>*/}
                {/*        <AutomaticRobotIcon/>*/}
                {/*    </Button>*/}
                {/*</ButtonGroup>*/}
            </Grid>
        </Grid>
    </Grid>
})
