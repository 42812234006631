import {observer} from "mobx-react-lite";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    LinearProgress
} from "@mui/material";
import {Check, Clear, KeyboardDoubleArrowDown, KeyboardDoubleArrowRight, Refresh, Search} from "@mui/icons-material";
import React from "react";
import {useStores} from "../../../../stores";

type Props = {
    isParent: boolean
}
export const ParentSupplierToolbar: React.FC<Props> = observer(({isParent}) => {
    const {supplierNormalizationStore} = useStores();

    const searchTitle = isParent ? 'Parent Supplier search' : 'Search matches';

    const showHits = supplierNormalizationStore.review.filterDelegate.hits !== 0
        && supplierNormalizationStore.review.filterDelegate.parentSupplierSearch.activeSearchString;

    return <Grid container spacing={2} alignItems="flex-end" justifyContent="flex-start"
                 className="parent-suppliers-toolbar">
        <Grid item>
            <Button
                variant="outlined"
                className="expand-button"
                onClick={() => supplierNormalizationStore.review.toggleAllOpen()}
                disabled={supplierNormalizationStore.review.isLoadingSubRows
                    || supplierNormalizationStore.review.isLoadingRows}>
                {supplierNormalizationStore.review.allOpen === true
                    ? <KeyboardDoubleArrowDown/> : <KeyboardDoubleArrowRight/>}
            </Button>
        </Grid>
        <Grid item>
            <FormControl variant="standard" fullWidth={true} className="search-field">
                <InputLabel htmlFor="parent-supplier-search-field">
                    {searchTitle}
                    {showHits &&
                        ` (${supplierNormalizationStore.review.filterDelegate.hits} result${supplierNormalizationStore.review.filterDelegate.hits === 1 ? '' : 's'})`
                    }
                </InputLabel>
                <Input
                    id="parent-supplier-search-field"
                    type="text"
                    fullWidth={true}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="clear search"
                                onClick={() => supplierNormalizationStore.review.filterDelegate.parentSupplierSearch.clearSearch()}
                            >
                                <Clear/>
                            </IconButton>
                            <IconButton
                                aria-label="search"
                                onClick={() => supplierNormalizationStore.review.filterDelegate.parentSupplierSearch.doSearch()}
                            >
                                <Search/>
                            </IconButton>
                        </InputAdornment>
                    }
                    value={supplierNormalizationStore.review.filterDelegate.parentSupplierSearch.searchString}
                    onChange={e => supplierNormalizationStore.review.filterDelegate.parentSupplierSearch.setSearch(e.target.value)}
                    onKeyDown={e => e.key === 'Enter' ? supplierNormalizationStore.review.filterDelegate.parentSupplierSearch.doSearch() : undefined}
                />
            </FormControl>
        </Grid>
        <Grid item>
            <FormControlLabel
                label="Standalone supplier"
                control={<Checkbox
                    checked={supplierNormalizationStore.review.filterDelegate.filter.showStandaloneSuppliers}
                    onClick={() => supplierNormalizationStore.review.filterDelegate.toggleShowStandAloneSuppliers()}
                />}/>
        </Grid>
        <div style={{flexGrow: 1}}/>
        <Grid item>
            <Button
                variant="outlined"
                className="refresh-button"
                onClick={() => supplierNormalizationStore.refreshReview()}
                disabled={supplierNormalizationStore.review.isLoadingSubRows
                    || supplierNormalizationStore.review.isLoadingRows
                    || supplierNormalizationStore.review.isProcessing}>
                <Refresh/>
            </Button>
        </Grid>
        <Grid item className="save-progress-wrapper">
            {supplierNormalizationStore.review.isProcessing ? 'Saving...' : <Check/>}
            <LinearProgress
                variant="determinate"
                value={1 / (supplierNormalizationStore.review.storeSupplierReviewUpdates.nInPipe + 1) * 100}
            />
        </Grid>
    </Grid>
})
