import {DashboardTypeItem, DashboardTypeMenu} from "../../../components/dashboard/DashboardTypeMenu";
import {DashboardEnvironmentV2} from "../../../stores/RootAppStore";
import {CmpDataBagRouteMatch, routes} from "../../../routing/routes";
import {generatePath, useHistory} from "react-router";
import React from "react";
import {useRouteMatch} from "react-router-dom";
import {useStores} from "../../../stores";
import {observer} from "mobx-react-lite";

const ITEMS: DashboardTypeItem<DashboardEnvironmentV2 | 'master'>[] = [
    {key: 'cleanup', label: 'Cleanup'},
    {key: 'analytics', label: 'Analytics'},
    {key: 'merge', label: 'Merge'},
    // {key: 'all', label: 'All'},
    {key: 'master', label: 'Master'},
];

export const DashboardSwitcher: React.FC = observer(() => {
    const {
        rootAppStore,
        bagStore,
        cmpStore,
        baseSupplierNormalizationStore,
        masterSupplierNormalizationStore,
    } = useStores();
    const routeMatch = useRouteMatch<CmpDataBagRouteMatch>();
    const history = useHistory();
    let selectedKey: DashboardEnvironmentV2 | 'master';

    if (rootAppStore.app.dataType === 'master') {
        selectedKey = 'master';
    } else {
        selectedKey = rootAppStore.app.dashboardEnvironment || 'all';
    }

    return <DashboardTypeMenu
        items={ITEMS}
        selectedKey={selectedKey}
        onSelect={(key) => {
            const path = key === 'cleanup'
                ? routes.merge_x_cleanup_dashboard
                : key === 'analytics'
                    ? routes.merge_x_analytics_dashboard
                    : key === 'merge'
                        ? routes.merge_x_merge_dashboard
                        : key === 'master'
                            ? routes.merge_x_master_dashboard
                            : routes.merge_x_dashboard;
            if (path) {
                const url = generatePath(path, routeMatch.params);
                history.push(url);
            }
        }}
    />
})