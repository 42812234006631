import {Redirect, Route} from "react-router-dom";
import React from "react";
import {RouteComponentProps, useLocation} from "react-router";
import {useStores} from "../stores";
import {routes} from "./routes";
import {RouteWrapperProps} from "../jobs/wrapper/RouteWrapperProps";
import {DEFAULT_APP_STATE} from "../stores/RootAppStore";

export type RouteSpec = React.FC<RouteWrapperProps> | [React.FC<RouteWrapperProps>, RouteWrapperProps['state']];

interface Props {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    notStrict?: boolean;

    [routeProps: string]: any;
}

export const AuthRoute: React.FC<Props> = ({component: Component, ...routeProps}) => {
    const {authStore} = useStores();
    const location = useLocation();
    const loggedIn = authStore.isLoggedIn();
    // console.log('AuthenticatedRoute', {requestedUrl: location.pathname, loggedIn});

    if (!routeProps.notStrict) {
        routeProps.strict = true;
    }

    const render = props => {
        if (!loggedIn) {
            return <Redirect to={{pathname: routes.login, state: location.pathname}}/>;
        }
        if (routeProps.w) {
            const w = routeProps.w as RouteSpec;
            let Wrapper: React.FC<RouteWrapperProps>;
            let WrapperProps: RouteWrapperProps;
            if (Array.isArray(w)) {
                let state: RouteWrapperProps['state'];
                [Wrapper, state] = w;
                WrapperProps = {state};
            } else {
                Wrapper = w;
                WrapperProps = {state: DEFAULT_APP_STATE};
            }
            return <Wrapper {...WrapperProps}><Component {...props}/></Wrapper>;
        }
        return <Component {...props}/>
    }

    return <Route {...routeProps} render={render}/>;
}
