import './MergeXDashBoard.scss';
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {DashboardGridAlert} from "../DashboardGridAlert";
import {MergeXAnalyticsDashboard} from "./analytics/MergeXAnalyticsDashboard";
import {MergeXCleanupDashboard} from "./cleanup/MergeXCleanupDashboard";
import {MergeXMergeDashboard} from "./merge/MergeXMergeDashboard";
import {MergeXMasterDashboard} from "./master/MergeXMasterDashboard";


/**
 * Dashboard that uses only merge-1 links, without any databags in the url
 */
export const MergeXDashboard: React.FC = observer(() => {
    const {
        rootAppStore,
        bagStore,
        cmpStore,
        baseSupplierNormalizationStore,
        masterSupplierNormalizationStore,
    } = useStores();
    const baseBagId = bagStore.bag?.id;
    const masterBagId = cmpStore.masterBag?.id;
    useEffect(() => {
        if (baseBagId && masterBagId) {
            masterSupplierNormalizationStore._postStatsRequestManager.request({databag: masterBagId})
            baseSupplierNormalizationStore._postStatsRequestManager.request({databag: baseBagId})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseBagId, masterBagId])

    let dashboard: React.ReactNode;
    if (rootAppStore.app.dataType === 'master') {
        dashboard = <MergeXMasterDashboard/>
    } else {
        switch (rootAppStore.app.dashboardEnvironment) {
            case 'cleanup':
                dashboard = <MergeXCleanupDashboard/>;
                break;
            case 'analytics':
                dashboard = <MergeXAnalyticsDashboard/>;
                break;
            case 'merge':
                dashboard = <MergeXMergeDashboard/>;
                break;
            default:
                dashboard = <h1>TODO: all</h1>;
                break;
        }
    }

    return <>
        <DashboardGridAlert/>
        {dashboard}
    </>
});
