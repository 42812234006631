import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {Grid} from "@mui/material";
import {DashboardRow} from "../../../../components/dashboard/DashboardRow";
import {DASHBOARD_CONSTANTS, drawOpportunityValue} from "../../../../components/dashboard/Dashboard.utils";
import {DashBoardCard} from "../../../dashboard/DashBoardCard";
import {generatePath} from "react-router";
import {routes} from "../../../../routing/routes";
import {CurrencyComponent} from "../../../../components/currency-component/CurrencyComponent";
import {ClassificationIcon} from "../../../../components/icons/ClassificationIcon";
import {TaxonomyMapperIcon} from "../../../../components/icons/TaxonomyMapperIcon";
import {environment} from "../../../../env";

/**
 * Dashboard that uses only merge-1 links, without any databags in the url
 */
export const MergeXMergeDashboard: React.FC = observer(() => {
    const {
        p,
        bagStore,
        cmpStore,
        categorizationStore,
        baseSupplierNormalizationStore,
        masterSupplierNormalizationStore,
        supplierNormalizationStore,
        taxonomyManagerStore,
        taxonomyMapperStore,
    } = useStores();
    const bagId = bagStore.bagId;
    const pendingSupplierNormalizationMaster = masterSupplierNormalizationStore.postKeyValues?.total_standalone_suppliers_spend;
    const pendingSupplierNormalizationBase = baseSupplierNormalizationStore.postKeyValues?.total_standalone_suppliers_spend;
    const masterCommonKpi = cmpStore.masterKpi;
    const baseCommonKpi = bagStore.kpi.common;
    const unclassifiedEgoSpend = categorizationStore.data.summaryResultKeyValues?.after.unclassified_spend;
    const pendingSupplierNormalization = supplierNormalizationStore.postKeyValues?.total_standalone_suppliers_spend;
    const nCategories = bagStore.kpi.common ? String(bagStore.kpi.common.n_categories) : DASHBOARD_CONSTANTS.SUBTITLE_LOADING;
    const unmatchedCategories = taxonomyMapperStore.mapper.unMatchedCategories?.length;

    const baseBagId = bagStore.bag?.id;
    const masterBagId = cmpStore.masterBag?.id;
    useEffect(() => {
        if (baseBagId && masterBagId) {
            masterSupplierNormalizationStore._postStatsRequestManager.request({databag: masterBagId})
            baseSupplierNormalizationStore._postStatsRequestManager.request({databag: baseBagId})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseBagId, masterBagId])

    return <>
        {environment.isTest && <h1>MergeXMergeDashboard</h1>}
        <Grid container
              className="dashboard merge-x-dashboard merge-dashboard"
              justifyContent="space-evenly"
              spacing={0}>
            <DashboardRow
                title="Suppliers"
                className="suppliers-row"
                subtitle={bagStore.kpi.common
                    ? String(bagStore.kpi.common.n_suppliers)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>

            </DashboardRow>
            <DashboardRow
                title="Categories"
                className="categories-row"
                subtitle={bagStore.kpi.common
                    ? String(bagStore.kpi.common.n_categories)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>

                {/*<DashBoardCard*/}
                {/*    cardKey="taxonomy-builder"*/}
                {/*    avatar={<ClassificationHierarchyIcon/>}*/}
                {/*    title="Taxonomy Viewer (baseline)"*/}
                {/*    subtitle={taxonomyManagerStore.taxonomy?.name}*/}
                {/*    link={generatePath(routes.merge_x_master_taxonomy_viewer_baseline, {id: bagId})}*/}
                {/*/>*/}
                {/*<DashBoardCard*/}
                {/*    cardKey="taxonomy-builder"*/}
                {/*    avatar={<ClassificationHierarchyIcon/>}*/}
                {/*    title="Taxonomy Builder"*/}
                {/*    subtitle={taxonomyManagerStore.taxonomy?.name}*/}
                {/*    link={generatePath(routes.merge_x_master_taxonomy_builder_target, {id: bagId})}*/}
                {/*/>*/}

                <DashBoardCard
                    cardKey="improve-cats"
                    avatar={<ClassificationIcon/>}
                    title="Improve Categorization"
                    value={drawOpportunityValue(unclassifiedEgoSpend)}
                    valueColor={unclassifiedEgoSpend
                        ? (unclassifiedEgoSpend >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}
                    subtitle="uncategorized"
                    link={generatePath(routes.merge_x_merge_categorization, {id: bagId})}
                    badge="Ai"
                />

                <DashBoardCard
                    cardKey="taxonomy-mapper"
                    avatar={<TaxonomyMapperIcon/>}
                    title="Taxonomy Mapper (baseline)"
                    value={unmatchedCategories === undefined
                        ? DASHBOARD_CONSTANTS.SUBTITLE_LOADING : unmatchedCategories}
                    subtitle="unmapped categories"
                    link={generatePath(routes.merge_x_merge_taxonomy_mapper_baseline, {id: bagId})}
                    badge="Ai"
                />
                <DashBoardCard
                    cardKey="taxonomy-mapper"
                    avatar={<TaxonomyMapperIcon/>}
                    title="Taxonomy Mapper"
                    value={unmatchedCategories === undefined
                        ? DASHBOARD_CONSTANTS.SUBTITLE_LOADING : unmatchedCategories}
                    subtitle="unmapped categories"
                    link={generatePath(routes.merge_x_merge_taxonomy_mapper_target, {id: bagId})}
                    badge="Ai"
                />

            </DashboardRow>
            <DashboardRow
                title="Spend"
                className="spend-row"
                subtitle={bagStore.kpi.common
                    ? <CurrencyComponent v={bagStore.kpi.common.total_spend} nDigits={3}/>
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>

                {/*<DashBoardCard*/}
                {/*    cardKey="taxonomy-mapper"*/}
                {/*    avatar={<TaxonomyMapperIcon/>}*/}
                {/*    title="Taxonomy Compare (UN)"*/}
                {/*    value={unmatchedCategories === undefined*/}
                {/*        ? DASHBOARD_CONSTANTS.SUBTITLE_LOADING : unmatchedCategories}*/}
                {/*    subtitle={taxonomyManagerStore.taxonomy?.name}*/}
                {/*    link={generatePath(routes.merge_x_master_taxonomy_compare_un, {id: bagId})}*/}
                {/*/>*/}
                {/*<DashBoardCard*/}
                {/*    cardKey="taxonomy-mapper"*/}
                {/*    avatar={<TaxonomyMapperIcon/>}*/}
                {/*    title="Taxonomy Compare"*/}
                {/*    value={unmatchedCategories === undefined*/}
                {/*        ? DASHBOARD_CONSTANTS.SUBTITLE_LOADING : unmatchedCategories}*/}
                {/*    subtitle={taxonomyManagerStore.taxonomy?.name}*/}
                {/*    link={generatePath(routes.merge_x_master_taxonomy_compare_baseline, {id: bagId})}*/}
                {/*/>*/}


            </DashboardRow>
        </Grid>
    </>
});
