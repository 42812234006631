import React from "react";
import {observer} from "mobx-react-lite";
import {useHistory} from "react-router";
import {Button, ButtonBase, Card, Grid, Typography} from "@mui/material";
import {DashBoardBadge} from "./DashboardBadge";
import {DashboardCard} from "./dashboard-types";
import {useStores} from "../../stores";
import {Bag} from "../../services/classes/Bag";

type _Link = {
    action?: () => void,
    label?: string
    link: string
}
export type Link = string | _Link

type Props = {
    className?: string
    cardKey: DashboardCard,
    avatar?: React.ReactNode
    title: string
    value?: React.ReactNode
    valueColor?: 'green' | 'red' | 'gray' | '',
    subtitle?: string
    link: Link
    nxtLink?: Link
    nxt2Link?: Link
    badge?: string
}

export const DashBoardCard: React.FC<Props> = observer((
    {
        className,
        cardKey,
        avatar,
        title,
        value,
        valueColor,
        subtitle,
        link,
        nxtLink,
        nxt2Link,
        badge,
    }) => {
    const history = useHistory();
    const {p, bagStore} = useStores();
    const bag = bagStore.bag as Bag;
    const isTrial = !p.hasCard(cardKey, bag);

    const onlyTitle = !value && !subtitle;
    const renderLink = (link: Link | undefined, defaultName: string) => {
        if (!link) return <></>
        let _link: _Link;
        if (typeof link === 'string') {
            _link = {
                link: link,
                label: defaultName,
            }
        } else {
            _link = link
        }
        return <Button
            onClick={() => {
                if (_link.action) _link.action()
                history.push(_link.link)
            }}>
            {_link.label}
        </Button>
    }
    const cardBody = <>
        <Grid container spacing={0} className="dashboard-card-container">
            <Grid item container sm={3} justifyContent="center" alignItems="center" className="avatar">
                <Grid item>
                    {avatar}
                </Grid>
            </Grid>
            <Grid item sm={9} className="dashboard-card-container-r">
                <header>
                    <Typography variant="h3" component="h3" className="card-title">
                        {title}
                    </Typography>
                </header>
                <section className="card-content">
                    <Typography variant="body1" component="p" className={'value ' + valueColor}>
                        {value}
                    </Typography>
                    <Typography variant="subtitle1" component="p" className="text">
                        {subtitle}
                    </Typography>
                    {
                        // Only who the link to the page when we have more then 1 link
                        nxtLink && renderLink(link, 'Open')
                    }
                    {renderLink(nxtLink, 'Previes')}
                    {renderLink(nxt2Link, 'Bleeding')}
                </section>
            </Grid>
        </Grid>
        {badge && <DashBoardBadge title={badge}/>}
    </>
    className = 'dashboard-card'
        + (onlyTitle ? ' only-title' : '')
        + (isTrial ? ' is-trial' : '')
        + (className ? ` ${className}` : '')
    if (link && !nxtLink && !nxt2Link) {
        className += ' btn-base'
        return <ButtonBase
            className={className}
            onClick={() => {
                if (isTrial) {
                    return
                }
                history.push(typeof link === 'string' ? link : link.link)
            }}
        >
            <Card
                raised
                variant="elevation"
                elevation={4}>
                {cardBody}
            </Card>
        </ButtonBase>
    } else {
        className += ' no-btn-base'
        return <Card
            className={className}
            raised
            variant="elevation"
            elevation={4}>
            {cardBody}
        </Card>
    }
});
