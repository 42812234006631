import './SpendConcentration.scss';
import React, {useEffect, useState} from "react";
import {useStores} from "../../../stores";
import {Button, Grid, Typography} from "@mui/material";
import {KoiFacade} from "../../../components/koi-facade/KoiFacade";
import {SpendConcentrationHelp} from "./SpendConcentrationHelp";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../constants";
import {TopOtherVisualization} from "./viz/TopOtherVisualization";
import {ParetoOverviewVisualization} from "./viz/ParetoOverviewVisualization";
import {SupplierSpendVisualization} from "./viz/SupplierSpendVisualization";
import {SupplierBreakdown} from "./viz/SupplierBreakdown";
import {observer} from "mobx-react-lite";
import {SpendConcentrationBreadcrumbs} from "./SpendConcentrationBreadcrumbs";
import {SpendConcentrationSearchComponent} from './SpendConcentrationSearchComponent';
import {
    SupplierConsolidationOpportunityModal
} from "../../../pages/opportunities-trello/SupplierConsolidationOpportunityModal";
import {Add, Check} from "@mui/icons-material";
import {runInAction} from "mobx";
import {useRouteMatch} from "react-router-dom";
import {generateHomePath} from "../../../routing/routing.utils";

export const SpendConcentration: React.FC = observer(() => {
    const {rootAppStore, bagStore, spendConcentrationStore, opportunityStore, cmpStore, p} = useStores();
    const routeMatch = useRouteMatch();

    const bagId = bagStore.bagId;
    const taxonomySize = bagStore.taxonomy_size;
    useEffect(() => {
        spendConcentrationStore.init(bagId, taxonomySize);
    }, [spendConcentrationStore, bagId, taxonomySize]);

    let title = 'Spend per supplier';
    let selectedCategory = spendConcentrationStore.selectedCategoryLabel;
    if (selectedCategory) {
        let selectedCategoryName = selectedCategory === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : selectedCategory;
        // Or a breadcrumb here as well???
        title = `Spend per supplier (${selectedCategoryName})`
    }
    console.log('SpendConcentration', title);
    const [isOpportunityModelOpen, setOpportunityModalOpen] = useState(false);
    const opportunityCreated = opportunityStore.hasOpportunity(cmpStore.cmpCat.selectedOpportunityId)

    return <KoiFacade
        title="Spend Concentration"
        helpContent={<SpendConcentrationHelp/>}
        back={generateHomePath(routeMatch, {id: bagId}, rootAppStore.app)}
        className="koi-spend-concentration-page"
    >
        <section className="page-item">
            <SupplierConsolidationOpportunityModal isOpen={isOpportunityModelOpen} setOpen={setOpportunityModalOpen}/>

            <Grid container justifyContent="space-evenly">
                <Grid item xs={4}>
                    {
                        spendConcentrationStore.topValue && spendConcentrationStore.otherValue &&
                        <TopOtherVisualization
                            topValue={spendConcentrationStore.topValue}
                            otherValue={spendConcentrationStore.otherValue}
                        />
                    }
                </Grid>
            </Grid>

            <Grid container justifyContent="center">
                <Grid item md={9} xs={12}>
                    <Grid container justifyContent="end">
                        <Grid item xs={8}>
                            <SpendConcentrationBreadcrumbs/>
                        </Grid>
                    </Grid>
                    {spendConcentrationStore.groupedData && <ParetoOverviewVisualization
                        data={spendConcentrationStore.groupedData}
                        setSelected={d => {
                            if (spendConcentrationStore.canSelectLevelDeeper) {
                                spendConcentrationStore.selectNextCategoryDown(d.category)
                            } else {
                                spendConcentrationStore.selectCategorySibling(d.category);
                            }
                        }}
                    />}
                </Grid>
                <Grid item md={2} xs={false}/>
            </Grid>


            <Grid container justifyContent="space-evenly" className="page-item">
                <Grid item xs={5}>
                    <Typography variant="h6" align="center" className="pareto-chart-title">
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <SpendConcentrationSearchComponent/>
                </Grid>
                {/*<Grid item xs={2}>*/}
                {/*    <Button*/}
                {/*        variant="outlined"*/}
                {/*        disabled={!selectedCategory}*/}
                {/*        color="primary"*/}
                {/*        size="small"*/}
                {/*        // endIcon={<Undo>All categories</Undo>}*/}
                {/*        onClick={() => spendConcentrationStore.unsetSelectedCat()}>*/}
                {/*        Show all categories*/}
                {/*    </Button>*/}
                {/*</Grid>*/}
            </Grid>

            <Grid container justifyContent="space-evenly">
                <Grid item xs={8}>
                    {/*<p>*/}
                    {/*    Selection: {spendConcentrationStore.selectedCategoryKey}<br/>*/}
                    {/*    Suppliers in stats: {spendConcentrationStore.cumSuppliers?.allSuppliers.length} (possible overlap)*/}
                    {/*</p>*/}
                    <SupplierSpendVisualization
                        data={spendConcentrationStore.cumSuppliers}
                        // selectedCategoryKey={spendConcentrationStore.selectedCategoryKey}
                        setClickedSupplier={d => spendConcentrationStore.setSelectedSupplier(d.dataId, d.parentLabel, d.total.value)}
                    />
                </Grid>
            </Grid>

            {p.p.enableOpportunityTracker &&
                <Grid container justifyContent="space-evenly">
                    <Grid item>
                        <Button
                            variant="outlined"
                            startIcon={opportunityCreated ? <Check/> : <Add/>}
                            // disabled={!canCreateOpportunity}
                            onClick={() => runInAction(() => {
                                opportunityStore.isEdit = false;

                                opportunityStore.editingScOpportunity.title = '';
                                opportunityStore.editingScOpportunity.id = `${new Date()}`;
                                opportunityStore.editingScOpportunity.checked = false;
                                const data = opportunityStore.editingScOpportunity.data;
                                if (data.type === 'supplier_consolidation') {
                                    data.otherSupplierName = '';
                                    data.opportunitySize = 1000;
                                    data.notes = '';
                                } else {
                                    console.warn('Experimental...')
                                }
                                setOpportunityModalOpen(true);
                            })}>
                            Add opportunity
                        </Button>
                    </Grid>
                </Grid>
            }

            <Grid container justifyContent="space-evenly">
                <Grid item xs={8}>
                    <SupplierBreakdown data={spendConcentrationStore.selectedSupplierSpec}/>
                </Grid>
            </Grid>

        </section>
    </KoiFacade>
});
