import {
    CurrencyAbbreviation,
    findAbbreviationOfGroup,
    NO_ABBREVIATION
} from "../../../components/currency-component/CurrencyComponent";
import {ParentSupplier, ParentSupplierUserData} from "../delegates/ParentSupplierDelegate";

export type SuggestedParentSupplierSerializer = {
    id: number | null
    sp_id: string
    sp_name: string
}
export type ParentSupplierReviewRowSerializer = {
    id: number
    sp_id: string
    sp_name: string
    sp_city: string
    sp_country: string
    sp_region: string
    sp_context_1: string
    sp_context_2: string
    sp_context_3: string
    sp_total_spend: number
    sp_total_parts: number
    sp_total_l1s: string[]
    sp_total_bus: string[]
    sp_mixed_supplier_sources: boolean
    sp_n_suppliers: number
}
export type SupplierReviewRowSerializer = {
    id: number
    s_id: string
    s_name: string
    s_city: string
    s_country: string
    s_context_3: 'base' | 'master' | ''
    s_region: string
    s_total_spend: number
    s_total_parts: number
    s_total_l1s: string[]
    s_total_bus: string[]
    parent_supplier_review_row: number // Just the ID
    parent_supplier_id: number | null
    sp_id: string
    sp_name: string
    ai_suggested: boolean
    ai_score: number
    review_user_id: number | null
    review_notes: string
}
export type ParentSupplierReviewRowState = ParentSupplierReviewRowSerializer & {
    type: 'parent_supplier'
    subRows: SupplierReviewRowState[] | null
}
export type SupplierReviewRowState = SupplierReviewRowSerializer & {
    type: 'supplier'
    parent_row: ParentSupplierReviewRowSerializer
    parent_match: ParentSupplierMatch | null
}
type AnyRow = SupplierReviewRowState | ParentSupplierReviewRowState;


export type ApiParentSupplierMatch = { type: 'parent_supplier', data: ParentSupplier };
/**
 * Some kind of supplier parent match from the user or the backend
 */
export type ParentSupplierMatch =
    ApiParentSupplierMatch
    | { type: 'new_parent_supplier', data: ParentSupplierUserData }


export type ParentSupplierPreReviewStats = {
    total_reviewed_spend: number
    total_suppliers_count: number
    total_parent_suppliers_count: number
}
export type ParentSupplierPostReviewStats = ParentSupplierPreReviewStats & {
    total_standalone_suppliers_count: number
    total_standalone_suppliers_spend: number
}

export function getSpend(row: AnyRow): number {
    if (row.type === 'parent_supplier') {
        return row.sp_total_spend;
    } else {
        return row.s_total_spend;
    }
}

export function getAbbreviation(data: AnyRow[] | undefined | null): CurrencyAbbreviation {
    if (data) {
        const spends = data.map(d => getSpend(d))
        if (spends.length > 0)
            return findAbbreviationOfGroup(spends)
    }
    return NO_ABBREVIATION;
}
