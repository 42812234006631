import MithraMaterializedApi from "../../../services/MithraMaterializedApi";
import {SupplierNormalizationStore} from "../SupplierNormalizationStore";
import {makeAutoObservable, reaction} from "mobx";
import {PageResponseManager} from "../../../stores/managers/PageResponseManager";
import {
    ParentSupplierMatch,
    ParentSupplierReviewRowSerializer,
    ParentSupplierReviewRowState,
    SupplierReviewRowSerializer,
    SupplierReviewRowState
} from "../pages/SupplierNormalization.classes";
import {from, Subscription} from "rxjs";
import {PipeManager} from "../../../stores/managers/PipeManager";
import {ParentSupplierFilterDelegate} from "./ParentSupplierFilterDelegate";

type UpdateSupplierDataType = {
    supplierRowId: number
    newParentSupplier: ParentSupplierMatch
}

export type ParentSupplierQueryFilter = {
    parent_search: string | undefined;
    showStandaloneSuppliers: boolean
}

export class ParentSupplierReviewDelegate {
    readonly filterDelegate = new ParentSupplierFilterDelegate(this.supplierNormalizationStore, this);
    readonly _parentSupplierRowsRequest = new PageResponseManager<{ databag: number, filter: ParentSupplierQueryFilter }, ParentSupplierReviewRowState, ParentSupplierReviewRowSerializer>(
        20, // TODO: Page size ignored by PageResponseManager
        (page, {databag, filter}) => this.api.listParentSupplierReviewRows(databag, page, filter),
        (d) => {
            const row: ParentSupplierReviewRowState = {
                ...d,
                type: 'parent_supplier',
                subRows: null, // Will be filled later
            }
            return row;
        }
    )
    readonly storeSupplierReviewUpdates = new PipeManager<UpdateSupplierDataType>(
        ({supplierRowId, newParentSupplier}) => {
            let parent_supplier, sp_id, sp_name;
            if (newParentSupplier.type === 'parent_supplier') {
                parent_supplier = newParentSupplier.data.id;
                sp_id = newParentSupplier.data.sp_id;
                sp_name = newParentSupplier.data.sp_name;
            } else {
                parent_supplier = undefined;
                sp_id = undefined;
                sp_name = newParentSupplier.data.sp_name;
            }
            return from(this.api.storeSupplierReviewUpdate(supplierRowId, parent_supplier, sp_id, sp_name));
        }
    )

    isLoadingSubRows = false;
    subRowsSubscription: Subscription | undefined;
    allOpen: null | boolean = false;

    constructor(
        private api: MithraMaterializedApi,
        private supplierNormalizationStore: SupplierNormalizationStore,
    ) {
        makeAutoObservable(this)
        reaction(() => [
            this._parentSupplierRowsRequest.data,
        ] as const, ([parentSupplierRows]) => {
            if (this.subRowsSubscription) this.subRowsSubscription.unsubscribe();
            const databag = this.supplierNormalizationStore.databag;
            if (!databag || !parentSupplierRows) {
                this.setSubRowData([]);
                this.subRowsSubscription = undefined;
                return;
            }
            const rowIds = parentSupplierRows.map(row => row.id);
            if (rowIds.length === 0) {
                this.setSubRowData([]);
                this.subRowsSubscription = undefined;
                return;
            }
            this.subRowsSubscription = from(this.api.listSupplierReviewRows(databag, rowIds)).subscribe({
                next: resp => this.setSubRowData(resp.data),
                complete: () => console.log('listSupplierReviewRows complete'),
            });
        })
    }

    toggleAllOpen() {
        this.allOpen = !this.allOpen;
    }

    setAllOpen(allOpen: boolean | null) {
        this.allOpen = allOpen;
    }

    get isLoadingRows() {
        return this._parentSupplierRowsRequest.isLoading
            || this.supplierNormalizationStore.ps._parentSupplierManager.busy;
    }

    get isProcessing() {
        return this.storeSupplierReviewUpdates.hasInPipe;
    }

    setSubRowData(subRowData: SupplierReviewRowSerializer[]) {
        console.time('ParentSupplierReviewDelegate setSubRowData()');
        // Collect the part data grouped by supplier
        const parentDatas = this._parentSupplierRowsRequest.data;

        const parentIndices = new Map(parentDatas?.map((d, i) => ([d.id, i])));
        const groupedSubRows = new Map<number, SupplierReviewRowState[]>();
        for (const d of subRowData) {
            const foundParentMatch = this.supplierNormalizationStore.ps.findApiParentMatch(d.sp_id);
            if (!foundParentMatch) {
                console.warn('ParentSupplierReviewDelegate setSubRowData() foundParentMatch is undefined', d);
            }

            // interpret data
            const row: SupplierReviewRowState = {
                ...d,
                // (optional) add other fields here
                type: 'supplier',
                parent_row: undefined as any,  // Defined further below
                parent_match: foundParentMatch || null
            }

            const parentRowId = row.parent_supplier_review_row
            const ds = groupedSubRows.get(parentRowId)
            if (ds) {
                ds.push(row)
            } else {
                groupedSubRows.set(parentRowId, [row])
            }
        }

        // Apply to the view
        groupedSubRows.forEach((subRows, parentKey) => {
            const i = parentIndices.get(parentKey);
            if (i === undefined) return;
            if (!parentDatas) return;
            const parentData = parentDatas[i];
            if (!parentData) return

            subRows.forEach(p => p.parent_row = parentData) // Double link it for easy updating
            parentData.subRows = subRows;
            // (optionally) Calculate more fields of the parent row
        })
        this.isLoadingSubRows = false;
        console.log(`setSubRowData() of ${subRowData.length} parts`);
        console.timeEnd('ParentSupplierReviewDelegate setSubRowData()');
    }

    sendSubRowUpdate(subRow: SupplierReviewRowState, newValue: ParentSupplierMatch | null) {
        if (!newValue) {
            console.warn('ParentSupplierReviewDelegate sendSubRowUpdate() newValue is undefined', subRow, newValue);
            return;
        }
        this.storeSupplierReviewUpdates.process(subRow.id, {
            supplierRowId: subRow.id,
            newParentSupplier: newValue
        })
    }
}