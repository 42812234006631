import {makeAutoObservable,} from 'mobx';
import {Bag} from "../services/classes/Bag";
import MithraMaterializedApi from "../services/MithraMaterializedApi";
import ProfileStore from "./ProfileStore";
import {stores} from "./index";
import {History} from "history";
import {match} from "react-router";
import {BagKpiManager} from "./managers/BagKpiManager";
import {BusinessUnitManager} from "./managers/BusinessUnitManager";
import {generateGotoDashboardPath} from "../routing/routing.utils";

export class BagStore {

    bag: Bag | undefined = undefined;
    allBags?: Bag[] = undefined;

    kpi = new BagKpiManager(this.api, this);

    public readonly bu = new BusinessUnitManager(this.profileStore, this.api);

    constructor(private api: MithraMaterializedApi, private profileStore: ProfileStore) {
        makeAutoObservable(this);
    }

    setAllBags(allBags: Bag[]) {
        this.allBags = allBags;
    }

    get bagId(): number {
        return this.bag?.id || 0;
    }

    set(b: Bag) {
        this.bag = b;
        this.kpi.init(b);
        this.bu.init(b);
    }

    setBag(b: Bag) {
        // console.log('setBag', b.id);
        this.set(b);

        // TODO: Remove hack
        const currentTaxonomyId = this.profileStore.p.hardcodeTaxonomyId
            ? this.profileStore.p.hardcodeTaxonomyId : b.current_taxonomy_id;
        if (!currentTaxonomyId) {
            const errData = {bag: b.id, current_taxonomy_id: currentTaxonomyId};
            console.error('Cannot initialize review results as the current taxonomy id is missing', errData)
            stores.categorizationReviewStore.categories.arrest('No taxonomy exists')
        } else {
            stores.categorizationReviewStore.categories.fillCategoriesFromTaxonomy(currentTaxonomyId)
        }
        stores.categorizationStore.reInitialize(b.id, b.taxonomy_size)
    }

    changeBag(newBagId: any, history: History, routeMatch: match<any>) {
        const newBag = this.allBags?.find(b => b.id === newBagId) as Bag
        if (!newBag) {
            console.error('Cannot change bag to bagId:', newBagId)
            return;
        }
        history.push(generateGotoDashboardPath(routeMatch, {id: newBag.id}));
        this.set(newBag);
    }

    patch(b: Bag) {
        this.bag = b;
        return this.api.http.patch<Bag>(`/bag/${b.id}/`, {name: b.name})
    }

    construct(name: string) {
        return {
            id: -1,
            name,
            timestamp: '',
            current_status: undefined,
            inputs: []
        } as any as Bag;
    }

    getAllBags = () => this.api.getAllBags()

    get taxonomy_size() {
        return this.bag?.taxonomy_size || 3;
    }
}
