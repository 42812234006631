import React from "react";
import {observer} from "mobx-react-lite";
import {Button, Grid, Typography} from "@mui/material";
import {useStores} from "../../../stores";
import {HelpComponent} from "../../../components/help-component/HelpComponent";
import SupplierNormalizationSSHelp from "../SupplierNormalizationSSHelp";
import {ChevronRight} from "@mui/icons-material";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";

export const SupplierNormalizationWelcomeStep: React.FC = observer(() => {
    const {supplierNormalizationStore} = useStores();
    const keyValues = supplierNormalizationStore.preKeyValues;
    return <section className="page-item step">
        <Grid container justifyContent="space-evenly">
            <Grid item xl={5} lg={8} xs={12}
                  container justifyContent="space-around" style={{alignContent: 'space-around'}}
                  alignItems="center">
                <Grid className="key-value-container text-center font-bigger"
                      item xs={10}
                      container spacing={4}>
                    {/* Row 1 */}
                    <Grid item
                          container
                          style={{justifyContent: "space-between"}}>
                        <Grid item>
                            <Typography variant="caption">
                                Review
                                <br/>spend
                            </Typography>
                            <Typography variant="h5" className="font-weight-bold key-value">
                                {keyValues
                                    ? <CurrencyComponent v={keyValues.total_reviewed_spend}/>
                                    : <>&nbsp;</>}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                Total
                                <br/>suppliers
                            </Typography>
                            <Typography variant="h5" className="font-weight-bold key-value">
                                {keyValues
                                    ? keyValues.total_suppliers_count
                                    : <>&nbsp;</>}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                Parent
                                <br/>suppliers
                            </Typography>
                            <Typography variant="h5" className="font-weight-bold key-value">
                                {keyValues
                                    ? keyValues.total_parent_suppliers_count
                                    : <>&nbsp;</>}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <div style={{display: "inline-grid"}}>
                                <Typography variant="caption">
                                    Standalone
                                    <br/>suppliers *
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? keyValues.total_suppliers_count
                                        : <>&nbsp;</>}
                                </Typography>
                            </div>
                            <div style={{display: "inline-grid", marginLeft: "8px"}}>
                                <HelpComponent helpContent={<SupplierNormalizationSSHelp/>}/>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        <div className="text-center page-item-actions">
            <Button
                variant="outlined"
                color="primary"
                endIcon={<ChevronRight/>}
                onClick={() => supplierNormalizationStore.gotoRunModelState()}>
                Start model
            </Button>
        </div>
    </section>
})
