import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import './SupplierNormalization.scss';
import {useRouteMatch} from "react-router-dom";
import {useStores} from "../../stores";
import {Bag} from "../../services/classes/Bag";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {environment} from "../../env";
import {generateHomePath} from "../../routing/routing.utils";
import {ParentSuppliersCompare} from "./pages/parent-suppliers-compare/ParentSuppliersStep";

export const SupplierComparePage: React.FC = observer(() => {
    const {rootAppStore, p, bagStore, supplierNormalizationStore} = useStores();
    const routeMatch = useRouteMatch();
    const bagId = bagStore.bagId;
    const bag = bagStore.bag as Bag;

    useEffect(() => {
        if (!p.p.hardcodedCombinedBagId) throw new Error('No hardcodedCombinedBagId set');
        supplierNormalizationStore.init(p.p.hardcodedCombinedBagId, bag.taxonomy_size)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const activeState = supplierNormalizationStore.state;
    useEffect(() => {
        if (activeState === 'running') {
            setTimeout(() => {
                supplierNormalizationStore.goToState('clean_parents');
            }, environment.aiResultFakeTimeout);
        }
        if (activeState === 'applying') {
            setTimeout(() => {
                supplierNormalizationStore.goToState('done');
            }, environment.aiResultFakeTimeout);
        }
    }, [activeState, supplierNormalizationStore]);

    return <KoiFacade
        title="Common Suppliers"
        intro={<></>}
        back={generateHomePath(routeMatch, {id: bagId}, rootAppStore.app)}
        className="sup-norm-page">

        <ParentSuppliersCompare/>

    </KoiFacade>
});
