/* eslint-disable @typescript-eslint/no-unused-vars */
import {AppConfiguration} from "./app-configuration-types";

export const C: AppConfiguration = {
    package: '?',
    dashboard: '?',
    menu: '?',
    profile: {
        p_name_col_name: 'Name',
        p_description_col_name: 'Description',
        searchConfiguration: 'default_p_desc',
        locale: 'nl-nl',
        currency: 'EUR',
        useDataIngestion: true,
    },
}
