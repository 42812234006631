import React, {useEffect} from "react";
import {useStores} from "../../../stores";
import {Alert, Button, Grid, Typography} from "@mui/material";
import './Style.scss';
import {useTheme} from "@mui/styles";
import {BarChart} from "../../../components/visualization/BarChart";
import {KoiFacade} from "../../../components/koi-facade/KoiFacade";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import './KoiPurchasePriceVariance.scss';
import {PurchasePriceVarianceHelp} from "./PurchasePriceVarianceHelp";
import {Bag} from "../../../services/classes/Bag";
import {useRouteMatch} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {PpvTable} from "./PpvTable";
import {PPV_EXPORT_CONFIG} from "./PpvControllerStore";
import {generateHomePath} from "../../../routing/routing.utils";

export const KOIPurchasePriceVariance: React.FC = observer(() => {
    const {rootAppStore, bagStore, ppvControllerStore, materializedApi, p} = useStores();
    const bagId = bagStore.bagId;
    const theme = useTheme();
    const routeMatch = useRouteMatch();

    const taxonomySize = bagStore.taxonomy_size;
    useEffect(() => {
        ppvControllerStore.init(bagId, taxonomySize)
    }, [ppvControllerStore, bagId, taxonomySize])

    const [downloading, setDownloading] = React.useState<boolean>(false);
    const clickDownload = () => {
        setDownloading(true);
        const filename = 'ProductPriceVariance.xlsx';
        materializedApi.downloadPpvTable({
            databag: bagId,
            filename,
            fields: PPV_EXPORT_CONFIG.fields,
            headers: PPV_EXPORT_CONFIG.headers,
            currency_symbol: p.currencySymbol,
            currency_fields: PPV_EXPORT_CONFIG.currency_fields,
        })
            .then(resp => {
                const url = URL.createObjectURL(new Blob([resp.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
            })
            .finally(() => {
                setDownloading(false);
            })
    }

    return <KoiFacade
        title="Purchase Price Variance"
        helpContent={<PurchasePriceVarianceHelp/>}
        intro={<></>}
        back={generateHomePath(routeMatch, {id: bagId}, rootAppStore.app)}
        className="koi-ppv-page"
    >
        {ppvControllerStore.ppvDataDoesNotExist &&
            <Grid container justifyContent="space-evenly">
                <Grid item style={{margin: '4em'}}>
                    <Alert severity="warning">
                        This dataset is missing data to calculate the Purchase Price Variance.
                    </Alert>
                </Grid>
            </Grid>
        }
        <div className="page-item">
            {/* Header */}
            <Grid container justifyContent="space-evenly">
                {/*<Grid item xs={2}>*/}
                {/*    /!*<Typography variant="subtitle1">Spend per category</Typography>*!/*/}
                {/*</Grid>*/}
                <Grid item xs={5}>
                    <Typography variant="h6" align="center" className="bar-chart-title">
                        Sum of variance cost per category
                    </Typography>
                </Grid>
                <Grid item xs={4}/>
            </Grid>

            <Grid container justifyContent="space-evenly">
                {/*<Grid item xs={2}/>*/}
                <Grid item xs={4} md={6} xl={5}>
                    <section className="bar-chart-container">
                        {ppvControllerStore.ppvCategoryData === undefined
                            ? <p>Loading</p>
                            : ppvControllerStore.ppvCategoryData === null
                                ? <p>No data</p>
                                // TODO: Update this to our BarChartV2 as well
                                : <BarChart
                                    horizontal
                                    width={500} height={350}
                                    data={ppvControllerStore.ppvCategoryData}
                                    labelMargin={200}
                                    categoryAxisTitle="L1 categories"
                                    valueAxisTitle="Sum of variance cost"
                                    // onClicked={setSelectedCategoryL1}
                                />
                        }
                    </section>
                </Grid>
                <Grid item xs={8} md={6} xl={4}
                      container justifyContent="space-around" style={{alignContent: 'space-around'}}
                      alignItems="center">
                    <Grid className="key-value-container text-center font-bigger"
                          item xs={11}
                          container spacing={4}>
                        {/* Row 1 */}
                        <Grid item
                              container
                              style={{justifyContent: "space-between"}}>
                            <Grid item>
                                <Typography variant="caption">
                                    Total<br/>
                                    Suppliers
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {ppvControllerStore.totalSuppliers
                                        ? `${ppvControllerStore.totalSuppliers}`
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    Spend<br/>
                                    concentration
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {ppvControllerStore.spendConcentration
                                        ? (Math.round(ppvControllerStore.spendConcentration * 100) + '%')
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    Purchase Price<br/>
                                    Variance
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {ppvControllerStore.spendTotalPPV
                                        ? <CurrencyComponent v={ppvControllerStore.spendTotalPPV} nDigits={3}/>
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* Row 2 */}
                        <Grid item
                              container
                              style={{justifyContent: "space-evenly"}}>
                            <Grid item>
                                <Typography variant="caption">
                                    Total spend
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {ppvControllerStore.totalSpend
                                        ? <CurrencyComponent v={ppvControllerStore.totalSpend} nDigits={3}/>
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    Unclassified
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {ppvControllerStore.unclassifiedSpend
                                        ? <CurrencyComponent v={ppvControllerStore.unclassifiedSpend} nDigits={3}/>
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={9}>
                        <Typography variant="body1" className="key-value-caption">
                            Price variance cost reflects potential savings across the portfolio
                            to be explored and ultimately realized.
                        </Typography>
                    </Grid>

                </Grid>
                {/*<Grid item xs={2}/>*/}
            </Grid>
        </div>

        {/*<Grid container justifyContent="space-evenly" className="page-item">*/}
        {/*    <Grid item xs={12}>*/}
        {/*        <p>clickedSupplier2 = {JSON.stringify(selectedCategoryL1)}</p>*/}
        {/*    </Grid>*/}
        {/*</Grid>*/}

        <section className="page-item ppv-table-section">
            <Grid container justifyContent="flex-end">
                <Grid item xs={2} style={{textAlign: 'center', marginBottom: theme.spacing(2)}}>
                    <Button
                        style={{width: '13em'}}
                        variant="outlined"
                        color="primary"
                        onClick={() => clickDownload()}
                        disabled={downloading}>
                        {downloading ? 'Downloading...' : 'Download all'}
                    </Button>
                </Grid>
                {/*<Grid item xs={2}/>*/}
            </Grid>
            <Grid container justifyContent="space-evenly">
                {/*<Grid item xs={2}>*/}
                {/*{selectedSupplier === undefined*/}
                {/*    ? <></>*/}
                {/*    : <Typography variant="subtitle1">*/}
                {/*        Transactions related to supplier {selectedSupplier.name}*/}
                {/*    </Typography>}*/}
                {/*</Grid>*/}
                <Grid item xs={12} style={{minHeight: '10em'}}>
                    <PpvTable/>
                </Grid>
                {/*<Grid item xs={2}/>*/}
            </Grid>
        </section>
    </KoiFacade>
});
