/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useHistory, useLocation} from "react-router";
import {JobRouteMatch, MithraHistoryState} from "../../routing/routes";
import {useRouteMatch} from "react-router-dom";
import {useStores} from "../../stores";
import {NotFound} from "../../routing/NotFound";
import {LoadingBarComponent} from "../../components/loading-bar/LoadingBarComponent";
import {MainComponent} from "../../components/main/MainComponent";
import {LoadingSpinnerPie} from "../../components/loading-pie/LoadingSpinnerPie";
import {RouteWrapperProps} from "./RouteWrapperProps";
import {HeaderWrapper} from "./HeaderWrapper";

/**
 * Wrapper for the mergeX product offering
 */
export const MergeXEnvWrapper: React.FC<RouteWrapperProps> = observer(({children, state}) => {
    const {
        rootAppStore,
        bagStore,
        bagLoadingStore,
        cmpStore,
        taxonomyManagerStore,
        categorizationStore,
        categorizationReviewStore,
        p
    } = useStores();
    const location = useLocation<MithraHistoryState>();
    const match = useRouteMatch<JobRouteMatch>();
    const history = useHistory();
    const isNoData = state.dataType === 'no_data';

    // TODO: See if this can be optimized, probably by the usage of react Context
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => rootAppStore.setApp(state), [])

    // The magic that loads the data
    useEffect(() => {
        // if (!p.p.hardcodedBagId) throw new Error('No hardcodedBagId provided');
        // if (!p.p.hardcodeMasterBagId) throw new Error('No hardcoded hardcodeMasterBagId provided');
        // if (!p.p.hardcodedCombinedBagId) throw new Error('No hardcoded hardcodedCombinedBagId provided');
        // if (!p.p.hardcodedCategorizationBagId) throw new Error('No hardcoded hardcodedCategorizationBagId provided');
        // if (!p.p.masterTaxonomyId) throw new Error('No hardcoded masterTaxonomyId provided');
        // bagLoadingStore.loadBagWithoutLoadingStores(p.p.hardcodedBagId).then(bag => {
        //     console.log(`Wrapper: Bag ${p.p.hardcodedBagId} loaded`);
        //     cmpStore.setCompareBag(bag);
        // });
        // categorizationReviewStore.categories.fillCategoriesFromTaxonomy(p.p.masterTaxonomyId)
        // categorizationStore.setDesiredBag(p.p.hardcodedCategorizationBagId, 3);
        // cmpStore.initBags(p.p.hardcodeMasterBagId)
        //
        // taxonomyManagerStore.setDesiredTaxonomyId(p.p.masterTaxonomyId);

        if (!isNoData)
            bagLoadingStore.initBag(location, history, match)
    }, [])

    // const desiredMasterBagStr = cmpStore.desiredMasterBagId ? `(${cmpStore.desiredMasterBagId}) ` : '';
    // const masterError = `Could not find Master data ${desiredMasterBagStr}on the server anymore`;

    // console.debug('Re-rendering MultiEnvWrapper', state.dashEnv)
    return <MainComponent state={state} header={<HeaderWrapper state={state}/>}>
        <LoadingBarComponent/>
        {/*{*/}
        {/*    bagLoadingStore.bagNotFound*/}
        {/*        ? <NotFound msg={"Could not find data on the server anymore"}/>*/}
        {/*        : cmpStore.masterBagNotFound*/}
        {/*            ? <NotFound msg={masterError}/>*/}
        {/*            : bagStore.bag && cmpStore.masterBag*/}
        {/*                ? children*/}
        {/*                : <div className="loading-spinner">*/}
        {/*                    <LoadingSpinnerPie/>*/}
        {/*                </div>*/}
        {/*}*/}
        {
            isNoData
                ? (children)
                : (
                    bagLoadingStore.bagNotFound
                        ? <NotFound msg={"Could not find data on the server anymore"}/>
                        : bagStore.bag
                            ? children
                            : <div className="loading-spinner">
                                <LoadingSpinnerPie/>
                            </div>
                )
        }
    </MainComponent>;
});
