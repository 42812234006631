import {makeAutoObservable} from "mobx";

/**
 * TODO: CAT-712
 * @deprecated
 */
export type DashboardEnvironment =
    'simple'
    | 'dual'
    | 'multi'
    | 'nestle' // TODO: CAT-712

/**
 * @deprecated TODO: CAT-712 Remove old dashboard switching mechanism
 */
export type DashboardType = 'single' | 'synergy';

/**
 * The type of dataset that is loaded
 */
export type DatasetType = 'standalone' | 'master' | 'subsidiary' | 'no_data';

export type DashboardEnvironmentV2 = 'analytics' | 'cleanup' | 'all' | 'merge';

/**
 * Props that are passed to the route wrapper component
 */
export type AppState = {
    fluid: boolean
    /**
     * The versioning of the dashboard
     * @deprecated
     */
    dashEnv?: DashboardEnvironment
    /**
     * The type of dataset that is loaded
     * @deprecated Do not use 'default' or 'combined' anymore
     */
    dataType?: DatasetType | 'default' | 'combined'
    /**
     * The type of styling that is used in the page
     * @deprecated TODO: CAT-712 Remove old dashboard switching mechanism
     */
    dashType?: DashboardType
    /**
     * The environment of the dashboard that is being shown
     */
    dashboardEnvironment?: DashboardEnvironmentV2
}
export const DEFAULT_APP_STATE: AppState = {
    fluid: true,
}


export class RootAppStore {
    get app(): AppState {
        return this.routeApp || this.defaultApp;
    }

    routeApp: AppState | undefined;
    defaultApp: AppState;

    constructor() {
        makeAutoObservable(this);
        this.defaultApp = DEFAULT_APP_STATE;
    }

    setApp(app: AppState) {
        this.routeApp = app;
    }
}
